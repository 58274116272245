import { Colour, OPACITY_60, TRANSPARENT } from "./colours";
import type { DesignationStyleMap, Color, AttributeStyle, DesignationStyle } from "./types";
import { createStyleMap, generateColor } from "./utils";

import { RagStatusDescription } from "./RagStatusDescription";
import { SubstationDescription } from "./SubstationDescription";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { STREETMAP_LINE_COLOR } from "react-migration/domains/ownership/util/Colors";

export const DEFAULT_COLOUR: Color = [32, 100, 170, 100];
export const DEFAULT_LINE_WIDTH = 0;
export const DEFAULT_POINT_RADIUS = 5;
export const DEFAULT_ICON_SIZE = 36;

const FLOOD_ZONE_COLOUR: Color = Colour.TEAL_60;
const WETLANDS_COLOUR: Color = [46, 204, 113, OPACITY_60];
const DEVELOPMENT_SITES_COLOUR: Color = [150, 75, 0, OPACITY_60];
const FLUM_MIXED_USE_COLOUR: Color = [234, 81, 227, OPACITY_60];
const FLUM_SPECIAL_COLOUR: Color = [50, 50, 228, OPACITY_60];

const zoningStyles: DesignationStyleMap = createStyleMap({
  zoning_single_family: { fillColor: [255, 233, 147, OPACITY_60], labelName: "key_code" },
  zoning_two_family: { fillColor: [255, 214, 51, OPACITY_60], labelName: "key_code" },
  zoning_multi_family: { fillColor: [255, 124, 34, OPACITY_60], labelName: "key_code" },
  zoning_neighborhood_commercial: { fillColor: [255, 204, 215, OPACITY_60], labelName: "key_code" },
  zoning_general_commercial: { fillColor: [245, 140, 163, OPACITY_60], labelName: "key_code" },
  zoning_core_commercial: { fillColor: [235, 81, 115, OPACITY_60], labelName: "key_code" },
  zoning_retail_commercial: { fillColor: [225, 27, 70, OPACITY_60], labelName: "key_code" },
  zoning_special_commercial: { fillColor: [143, 6, 35, OPACITY_60], labelName: "key_code" },
  zoning_light_industrial: { fillColor: [198, 198, 198, OPACITY_60], labelName: "key_code" },
  zoning_industrial: { fillColor: [125, 125, 125, OPACITY_60], labelName: "key_code" },
  zoning_office: { fillColor: [74, 234, 221, OPACITY_60], labelName: "key_code" },
  zoning_mixed_use: { fillColor: [234, 81, 227, OPACITY_60], labelName: "key_code" },
  zoning_mixed_outer: { fillColor: [234, 81, 227, OPACITY_60], labelName: "key_code" },
  zoning_agriculture: { fillColor: [27, 223, 99, OPACITY_60], labelName: "key_code" },
  zoning_agriculture_outer: { fillColor: [27, 223, 99, OPACITY_60], labelName: "key_code" },
  zoning_planned: { fillColor: [81, 140, 234, 200], labelName: "key_code" },
  zoning_planned_outer: { fillColor: [81, 140, 234, 200], labelName: "key_code" },
  zoning_special: { fillColor: [50, 50, 228, OPACITY_60], labelName: "key_code" },
  zoning_special_outer: { fillColor: [50, 50, 228, OPACITY_60], labelName: "key_code" },
  zoning_overlay: { fillColor: [255, 0, 0, OPACITY_60], labelName: "key_code" },
  zoning_overlay_outer: { fillColor: [255, 0, 0, OPACITY_60], labelName: "key_code" },
});

const regionalAssessmentsUS: DesignationStyleMap = createStyleMap({
  us_state_demographics: {
    fillColor: TRANSPARENT,
    lineColor: Colour.MAGENTA_60,
    labelName: "display_name",
  },
  us_county_demographics: {
    fillColor: TRANSPARENT,
    lineColor: Colour.MAGENTA_80,
    labelName: "display_name",
  },
  us_tract_demographics: {
    fillColor: TRANSPARENT,
    lineColor: Colour.MAGENTA,
  },
  us_opportunity_zones: {
    fillColor: Colour.CYAN_60,
  },
});

const regionalAssessmentsUK: DesignationStyleMap = createStyleMap({
  [ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY]: {
    fillColor: TRANSPARENT,
    lineColor: Colour.MAGENTA_60,
    labelName: "display_name",
  },
  [ConstraintsCategory.UK_DEMOGRAPHICS_REGION]: {
    fillColor: TRANSPARENT,
    lineColor: Colour.MAGENTA_80,
    labelName: "display_name",
  },
  [ConstraintsCategory.UK_DEMOGRAPHICS_LAD]: {
    fillColor: TRANSPARENT,
    lineColor: Colour.MAGENTA_80,
  },
  [ConstraintsCategory.UK_DEMOGRAPHICS_MSOA]: {
    fillColor: TRANSPARENT,
    lineColor: Colour.MAGENTA,
  },
  [ConstraintsCategory.UK_DEMOGRAPHICS_LSOA]: {
    fillColor: TRANSPARENT,
    lineColor: Colour.MAGENTA,
  },
});

const flumStyles: DesignationStyleMap = createStyleMap({
  flum_single_family: { fillColor: [255, 233, 147, OPACITY_60], labelName: "display_name" },
  flum_two_family: { fillColor: [255, 214, 51, OPACITY_60], labelName: "display_name" },
  flum_neighborhood_commercial: {
    fillColor: [255, 204, 215, OPACITY_60],
    labelName: "display_name",
  },
  flum_general_commercial: { fillColor: [245, 140, 163, OPACITY_60], labelName: "display_name" },
  flum_core_commercial: { fillColor: [235, 81, 115, OPACITY_60], labelName: "display_name" },
  flum_retail_commercial: { fillColor: [225, 27, 70, OPACITY_60], labelName: "display_name" },
  flum_special_commercial: { fillColor: [143, 6, 35, OPACITY_60], labelName: "display_name" },
  flum_light_industrial: { fillColor: [198, 198, 198, OPACITY_60], labelName: "display_name" },
  flum_industrial: { fillColor: [125, 125, 125, OPACITY_60], labelName: "display_name" },
  flum_office: { fillColor: [74, 234, 221, OPACITY_60], labelName: "display_name" },
  flum_mixed_use: { fillColor: generateColor(FLUM_MIXED_USE_COLOUR, 1), labelName: "display_name" },
  flum_neighborhood_activity_center: {
    fillColor: generateColor(FLUM_MIXED_USE_COLOUR, 0.95),
    labelName: "display_name",
  },
  flum_downtown_urban_core: {
    fillColor: generateColor(FLUM_MIXED_USE_COLOUR, 0.9),
    labelName: "display_name",
  },
  flum_mixed_use_general: {
    fillColor: generateColor(FLUM_MIXED_USE_COLOUR, 0.85),
    labelName: "display_name",
  },
  flum_special_planning_area: {
    fillColor: generateColor(FLUM_MIXED_USE_COLOUR, 0.8),
    labelName: "display_name",
  },
  flum_mixed_use_rural: {
    fillColor: generateColor(FLUM_MIXED_USE_COLOUR, 0.75),
    labelName: "display_name",
  },
  flum_agriculture: {
    fillColor: [27, 223, 99, OPACITY_60],
    labelName: "display_name",
  },
  flum_agriculture_outer: { fillColor: [27, 223, 99, OPACITY_60], labelName: "display_name" },
  flum_planned: { fillColor: [81, 140, 234, 200], labelName: "display_name" },
  flum_planned_outer: { fillColor: [81, 140, 234, 200], labelName: "display_name" },
  flum_special: { fillColor: generateColor(FLUM_SPECIAL_COLOUR, 1), labelName: "display_name" },
  flum_federal: { fillColor: generateColor(FLUM_SPECIAL_COLOUR, 0.95), labelName: "display_name" },
  flum_institutional: {
    fillColor: generateColor(FLUM_SPECIAL_COLOUR, 0.9),
    labelName: "display_name",
  },
  flum_recreation: {
    fillColor: generateColor(FLUM_SPECIAL_COLOUR, 0.85),
    labelName: "display_name",
  },
  flum_unknown: { fillColor: generateColor(FLUM_SPECIAL_COLOUR, 0.8), labelName: "display_name" },
  flum_native_american_lands: {
    fillColor: generateColor(FLUM_SPECIAL_COLOUR, 0.75),
    labelName: "display_name",
  },
  flum_water: { fillColor: generateColor(FLUM_SPECIAL_COLOUR, 0.7), labelName: "display_name" },
  flum_conservation: {
    fillColor: generateColor(FLUM_SPECIAL_COLOUR, 0.65),
    labelName: "display_name",
  },
  flum_transportation_utilities: {
    fillColor: generateColor(FLUM_SPECIAL_COLOUR, 0.6),
    labelName: "display_name",
  },
});

const floodZoneStyles = createStyleMap({
  us_flood_zones_unknown_risk_area_with_risk_due_to_levee: generateColor(FLOOD_ZONE_COLOUR, 1),
  us_flood_zones_unknown_risk_area_of_undetermined_flood_hazard: generateColor(
    FLOOD_ZONE_COLOUR,
    1
  ),
  "us_flood_zones_minimal_risk_future_conditions_1%_annual_chance_flood_hazard": generateColor(
    FLOOD_ZONE_COLOUR,
    0.9
  ),
  us_flood_zones_minimal_risk: generateColor(FLOOD_ZONE_COLOUR, 0.9),
  us_flood_zones_minimal_risk_area_with_reduced_risk_due_to_levee: generateColor(
    FLOOD_ZONE_COLOUR,
    0.9
  ),
  "us_flood_zones_moderate_risk_0.2%_annual_chance_flood_hazard": generateColor(
    FLOOD_ZONE_COLOUR,
    0.8
  ),
  us_flood_zones_high_risk_special_floodway: generateColor(FLOOD_ZONE_COLOUR, 0.7),
  "us_flood_zones_high_risk_1%_annual_chance_flood_hazard": generateColor(FLOOD_ZONE_COLOUR, 0.7),
  "us_flood_zones_high_risk_coastal_1%_annual_chance_flood_hazard": generateColor(
    FLOOD_ZONE_COLOUR,
    0.7
  ),
  us_flood_zones_high_risk_regulatory_floodway: generateColor(FLOOD_ZONE_COLOUR, 0.7),
  us_flood_zones_high_risk_coastal_regulatory_floodway: generateColor(FLOOD_ZONE_COLOUR, 0.7),
  us_flood_zones_other: generateColor(FLOOD_ZONE_COLOUR, 0.7),
});

const wetlandStyles: DesignationStyleMap = createStyleMap(
  Object.fromEntries(
    [
      "us_wetlands_estuarine_and_marine_deepwater",
      "us_wetlands_estuarine_and_marine_wetland",
      "us_wetlands_freshwater_emergent_wetland",
      "us_wetlands_freshwater_forested/shrub_wetland",
      "us_wetlands_freshwater_pond",
      "us_wetlands_lake",
      "us_wetlands_riverine",
      "us_wetlands_other",
    ].map((key, index) => [key, generateColor(WETLANDS_COLOUR, 1 - 0.05 * index)])
  )
);

const floodRiskStyles: DesignationStyleMap = createStyleMap(
  Object.fromEntries(
    [
      "risk_of_flooding_from_rivers_and_seas",
      "very_low_risk_of_flooding_from_rivers_and_seas",
      "low_risk_of_flooding_from_rivers_and_seas",
      "medium_risk_of_flooding_from_rivers_and_seas",
      "high_risk_of_flooding_from_rivers_and_seas",
    ].map((key, index) => [key, generateColor(Colour.CYAN_60, 1 - 0.1 * index)])
  )
);

const powerFillColourStyle = {
  attribute: "rag_status",
  domain: ["RED", "AMBER", "GREEN"],
  range: [Colour.RED, Colour.ORANGE, Colour.GREEN],
  unknown: Colour.WHITE,
};

const powerLineColourStyle = {
  attribute: "rag_status",
  domain: ["RED", "AMBER", "GREEN"],
  range: [Colour.RED, Colour.ORANGE, Colour.GREEN],
  unknown: Colour.MAGENTA,
};

const powerStyles = createStyleMap({
  power_cables: [249, 140, 13, 255],
  power_overhead_lines: [249, 180, 14, 255],
  power_towers: [249, 210, 19, 255],

  grid_power_substations: {
    pointRadius: 32,
    lineWidth: 2,
    fillColor: powerFillColourStyle,
    lineColor: powerLineColourStyle,
  },
  bulk_power_substations: {
    pointRadius: 20,
    lineWidth: 2,
    fillColor: powerFillColourStyle,
    lineColor: powerLineColourStyle,
  },
  primary_power_substations: {
    pointRadius: 12,
    lineWidth: 2,
    fillColor: powerFillColourStyle,
    lineColor: powerLineColourStyle,
  },
  hv_power_substations: {
    pointRadius: 8,
    lineWidth: 2,
    fillColor: powerFillColourStyle,
    lineColor: powerLineColourStyle,
  },
});

const schoolRatingFillColourStyle: AttributeStyle<Color> = {
  attribute: "ofsted_rating",
  domain: [
    "Outstanding",
    "Good",
    "Requires Improvement",
    "Serious Weaknesses",
    "Special Measures",
    "Inadequate",
  ],
  range: [
    [56, 156, 0],
    [70, 198, 82],
    [229, 141, 0],
    [237, 28, 36],
    [237, 28, 36],
    [237, 28, 36],
  ],
  unknown: [131, 134, 137],
};

const commonAmenityIconStyles: Partial<DesignationStyle> = {
  iconSize: 36,
  pointRadius: 16,
  lineColor: [255, 255, 255],
  lineWidth: 2,
};

type AmenityCategories = (typeof AMENITY_CATEGORIES)[number];

export const amenityStyles = createStyleMap<AmenityCategories>({
  bus_stop: {
    ...commonAmenityIconStyles,
    fillColor: [123, 204, 196, 255],
    icon: "lt-bus-line",
  },
  train_station: {
    ...commonAmenityIconStyles,
    fillColor: [67, 162, 202, 255],
    icon: "lt-national-rail",
  },
  metro_station: {
    ...commonAmenityIconStyles,
    fillColor: [8, 104, 172, 255],
    icon: "lt-train-line",
  },
  primary_school: {
    ...commonAmenityIconStyles,
    fillColor: schoolRatingFillColourStyle,
    icon: "lt-graduation-cap-line",
  },
  primary_school_independent: {
    ...commonAmenityIconStyles,
    fillColor: [28, 64, 148],
    icon: "lt-graduation-cap-line",
  },
  secondary_school: {
    ...commonAmenityIconStyles,
    fillColor: schoolRatingFillColourStyle,
    icon: "lt-graduation-cap-line",
  },
  secondary_school_independent: {
    ...commonAmenityIconStyles,
    fillColor: [28, 64, 148],
    icon: "lt-graduation-cap-line",
  },
  further_education: {
    ...commonAmenityIconStyles,
    fillColor: schoolRatingFillColourStyle,
    icon: "lt-graduation-cap-line",
  },
  further_education_independent: {
    ...commonAmenityIconStyles,
    fillColor: [28, 64, 148],
    icon: "lt-graduation-cap-line",
  },
  supermarkets_and_convenience_stores: {
    ...commonAmenityIconStyles,
    fillColor: [254, 153, 41, 255],
    icon: "lt-store-line",
  },
  gp_surgeries: {
    ...commonAmenityIconStyles,
    fillColor: [237, 28, 36, 255],
    icon: "lt-hospital-line",
  },
  clinics: {
    ...commonAmenityIconStyles,
    fillColor: [237, 28, 36, 255],
    icon: "lt-hospital-line",
  },
  pharmacies: {
    ...commonAmenityIconStyles,
    fillColor: [56, 156, 0, 255],
    icon: "lt-add-line",
  },
  dentists: {
    ...commonAmenityIconStyles,
    fillColor: [0, 0, 0, 255],
    icon: "lt-tooth-line",
  },
  hospitals: {
    ...commonAmenityIconStyles,
    fillColor: [0, 94, 184, 255],
    icon: "lt-hospital-line",
  },
});

type miningAndGeologyCategories = (typeof MINING_AND_GEOLOGY_CATEGORIES)[number];

export const miningAndGeologyStyles = createStyleMap<miningAndGeologyCategories>({
  coal_mining_reporting_areas: {
    fillColor: [255, 137, 27],
    fillPattern: "dots",
    fillPatternScale: 0.8,
    lineColor: [255, 137, 27],
  },
  mine_entries: {
    fillColor: [255, 0, 0],
    pointRadius: 3,
    lineColor: [255, 255, 255],
    lineWidth: 1,
  },
  coal_development_high_risk_areas: {
    fillColor: [255, 137, 27, OPACITY_60],
    fillPatternScale: 0.8,
    lineColor: [255, 137, 27],
  },
  surface_mining: {
    fillColor: [82, 82, 82, OPACITY_60],
    lineColor: [82, 82, 82],
  },
  probable_shallow_coal_workings: {
    fillColor: [155, 0, 106, OPACITY_60],
    lineColor: [155, 0, 106],
  },
  past_shallow_coal_workings: {
    fillColor: [21, 136, 181, OPACITY_60],
    lineColor: [21, 136, 181],
  },
});

type usAmenityCategories = (typeof US_AMENITY_CATEGORIES)[number];

export const usAmenityStyles = createStyleMap<usAmenityCategories>({
  us_schools_elementary: {
    ...commonAmenityIconStyles,
    fillColor: [158, 188, 218],
    icon: "lt-graduation-cap-line",
  },
  us_schools_middle: {
    ...commonAmenityIconStyles,
    fillColor: [140, 150, 198],
    icon: "lt-graduation-cap-line",
  },
  us_schools_high: {
    ...commonAmenityIconStyles,
    fillColor: [136, 86, 167],
    icon: "lt-graduation-cap-line",
  },
  us_schools_combination: {
    ...commonAmenityIconStyles,
    fillColor: [129, 15, 124],
    icon: "lt-graduation-cap-line",
  },
  us_schools_other: {
    ...commonAmenityIconStyles,
    fillColor: [191, 211, 230],
    icon: "lt-graduation-cap-line",
  },
  us_schools_private_elementary: {
    iconSize: 32,
    fillColor: [186, 228, 188],
    icon: "lt-graduation-cap-line",
  },
  us_schools_private_secondary: {
    iconSize: 32,
    fillColor: [123, 204, 196],
    icon: "lt-graduation-cap-line",
  },
  us_schools_private_combined: {
    iconSize: 32,
    fillColor: [43, 140, 190],
    icon: "lt-graduation-cap-line",
  },
});

type usUtilityCategories = (typeof US_UTILITY_CATEGORIES)[number];

export const usUtilityStyles = createStyleMap<usUtilityCategories>({
  urban_service_area: {
    fillColor: [255, 255, 255, 0],
    lineColor: [4, 137, 177],
    lineWidth: 3,
  },
  us_dw_known_public: { fillColor: [26, 53, 230, OPACITY_60] },
  us_dw_likely_public: { fillColor: [83, 197, 220, OPACITY_60] },
  us_dw_known_well: { fillColor: [88, 16, 233, OPACITY_60] },
  us_dw_likely_well: { fillColor: [200, 82, 202, OPACITY_60] },
  us_dw_conflicting: { fillColor: [235, 114, 22, OPACITY_60] },
  us_dw_unknown: { fillColor: [125, 125, 125, OPACITY_60] },

  us_ww_known_sewer: { fillColor: [131, 96, 19, OPACITY_60] },
  us_ww_likely_sewer: { fillColor: [223, 174, 12, OPACITY_60] },
  us_ww_known_septic: { fillColor: [0, 108, 34, OPACITY_60] },
  us_ww_likely_septic: { fillColor: [147, 198, 103, OPACITY_60] },
  us_ww_conflicting: { fillColor: [230, 59, 141, OPACITY_60] },
  us_ww_unknown: { fillColor: [125, 125, 125, OPACITY_60] },
});

type greyBeltCategories = (typeof GREY_BELT_CATEGORIES)[number];

export const greyBeltStyles = createStyleMap<greyBeltCategories>({
  grey_belt_land_most_favourable: { fillColor: [129, 15, 124, OPACITY_60] },
  grey_belt_land_more_favourable: { fillColor: [136, 86, 167, OPACITY_60] },
  grey_belt_land_average_favourability: { fillColor: [140, 150, 198, OPACITY_60] },
  grey_belt_land_less_favourable: { fillColor: [179, 205, 227, OPACITY_60] },
  grey_belt_land_least_favourable: { fillColor: [237, 248, 251, OPACITY_60] },

  declassified_green_belt: {
    fillColor: Colour.RED,
    fillPattern: "hatch-left",
    fillPatternScale: 0.8,
  },
});

export const CATEGORY_STYLES: DesignationStyleMap = createStyleMap({
  ...greyBeltStyles,
  ...usUtilityStyles,
  ...miningAndGeologyStyles,
  ...usAmenityStyles,
  ...amenityStyles,
  ...zoningStyles,
  ...flumStyles,
  ...floodZoneStyles,
  ...floodRiskStyles,
  ...wetlandStyles,
  ...regionalAssessmentsUS,
  ...regionalAssessmentsUK,
  ...powerStyles,
  us_permits_residential: Colour.YELLOW_60,
  us_permits_commercial: Colour.CYAN_60,
  us_building_permits_residential: {
    pointRadius: 8,
    fillColor: Colour.ORANGE_60,
    lineColor: Colour.WHITE,
    lineWidth: 1,
  },
  us_building_permits_commercial: {
    pointRadius: 8,
    fillColor: Colour.RED_60,
    lineColor: Colour.WHITE,
    lineWidth: 1,
  },

  rezoning_residential: {
    pointRadius: 8,
    fillColor: Colour.ORANGE_60,
    lineColor: Colour.WHITE,
    lineWidth: 1,
  },
  rezoning_commercial: {
    pointRadius: 8,
    fillColor: Colour.RED_60,
    lineColor: Colour.WHITE,
    lineWidth: 1,
  },
  rezoning_mixed_use: {
    pointRadius: 8,
    fillColor: [234, 81, 227, OPACITY_60],
    lineColor: Colour.WHITE,
    lineWidth: 1,
  },

  environmental_permits_residential: Colour.ORANGE_60,
  environmental_permits_commercial: Colour.RED_60,
  environmental_permits_other: [50, 50, 228, OPACITY_60],
  environmental_permits_approved: Colour.GREEN_60,
  environmental_permits_pending: Colour.YELLOW_60,
  environmental_permits_withdrawn: Colour.ORANGE_60,
  environmental_permits_rejected: Colour.RED_60,
  environmental_permits_unknown: Colour.BLUE_60,

  ssurgo: {
    fillColor: [255, 255, 255, 0],
    lineColor: [255, 127, 0, 204],
    lineWidth: 2,
  },
  ssurgo_hydric: [243, 166, 178, OPACITY_60],

  shlaa_negative: {
    fillColor: Colour.RED_60,
    lineColor: Colour.TEAL,
  },
  shlaa_neutral: {
    fillColor: Colour.YELLOW_60,
    lineColor: Colour.TEAL,
  },
  shlaa_positive: {
    fillColor: Colour.GREEN_60,
    lineColor: Colour.TEAL,
  },
  brownfield_land: { fillColor: Colour.PURPLE_60 },

  archaeological_priority_zones: [196, 164, 132, OPACITY_60],
  regionally_important_geological_site: [196, 164, 132, OPACITY_60],

  "conservation_areas_outer, conservation_areas": {
    fillColor: Colour.NAVY,
    fillPattern: "hatch-right",
    fillPatternScale: 0.8,
  },

  "green_belt_outer, green_belt": {
    fillColor: Colour.GREEN,
    fillPattern: "hatch-left",
    fillPatternScale: 0.8,
  },

  scottish_ownership: {
    fillColor: TRANSPARENT,
    lineColor: STREETMAP_LINE_COLOR,
    lineWidth: 1,
  },

  "site_allocations, uncategorised_allocation": Colour.APRICOT_60,
  commercial_allocation: Colour.APRICOT_60,
  community_allocation: Colour.APRICOT_60,
  employment_allocation: Colour.APRICOT_60,
  mixed_use_allocation: Colour.APRICOT_60,
  "housing_outer, housing_allocation, residential_areas_of_spectial_character":
    Colour.LIGHT_PINK_60,
  residential_allocation: Colour.LIGHT_PINK_60,
  "area_action_plans, action_area": Colour.BROWN_60,
  named_regeneration_area: Colour.MAROON_60,

  primary_employment_area: Colour.YELLOW_60,
  prefferred_office_location: Colour.YELLOW_60,
  secondary_employment_area: Colour.YELLOW_60,
  local_business_areas: Colour.YELLOW_60,
  flood_zone_3_env_agency: Colour.TEAL_60,
  flood_zone_2: Colour.TEAL_60,
  flood_zone_3a: Colour.TEAL_60,
  flood_zone_3b: Colour.TEAL_60,
  flood_zone: Colour.TEAL_60,
  flood_zone_2_env_agency: Colour.TEAL_60,
  nutrient_neutrality: Colour.LAVENDER_60,
  strategic_industrial_location: [246, 190, 0, OPACITY_60],
  specific_industrial_estate_policies: [246, 190, 0, OPACITY_60],
  special_industrial_zone: [246, 190, 0, OPACITY_60],
  local_industrial_location: [246, 190, 0, OPACITY_60],
  propoposed_cycle_networks: [210, 215, 211, OPACITY_60],
  cycle_network: [210, 215, 211, OPACITY_60],
  transport_improvements: [210, 215, 211, OPACITY_60],
  road_hierachy: [210, 215, 211, OPACITY_60],
  biosphere_reserve: Colour.MAGENTA_60,
  "site_of_special_scientific_interest_(sssi)": Colour.MAGENTA_60,
  "site_of_local_importance_for_nature_conservation_(linc)": Colour.MAGENTA_60,
  "borough_importance_for_nature_conservation_(binc)": Colour.MAGENTA_60,
  area_of_metropolitan_importance_for_nature_conservation: Colour.MAGENTA_60,
  local_nature_reserve: Colour.MAGENTA_60,
  wildlife_corridor: Colour.MAGENTA_60,
  aonb: Colour.MAGENTA_60,
  national_landscape: {
    fillColor: [179, 78, 0, 255],
    fillPattern: "hatch-right",
    fillPatternScale: 0.8,
  },
  national_scenic_areas: Colour.MAGENTA_60,
  national_nature_reserve: Colour.MAGENTA_60,
  ramsar_site: Colour.MAGENTA_60,
  special_area_of_conservation: Colour.MAGENTA_60,
  special_protected_area: Colour.MAGENTA_60,
  special_protection_area: Colour.MAGENTA_60,
  article_4: Colour.MAGENTA_60,
  development_risk: Colour.MAGENTA_60,
  site_characterisation: Colour.MAGENTA_60,
  environmental_constraint: Colour.MAGENTA_60,
  general_constraint: Colour.MAGENTA_60,
  ancient_woodland: Colour.MAGENTA_60,
  "retail_parks/_retail_centres": [246, 190, 0, OPACITY_60],
  settlement_boundaries: Colour.OLIVE_60,
  primary_shopping_frontages: [231, 84, 128, OPACITY_60],
  secondary_shopping_frontages: [231, 84, 128, OPACITY_60],
  central_activity_zone_frontages: [231, 84, 128, OPACITY_60],
  town_centre_boundary: [231, 84, 128, OPACITY_60],
  small_neighbourhood_centres: [231, 84, 128, OPACITY_60],
  "major_centre/_district_centre": [231, 84, 128, OPACITY_60],
  minor_centre: [231, 84, 128, OPACITY_60],

  [`
    statutory_listed_buildings,
    locally_listed_buildings,
    heritage_land_listed_buildings_outer,
    heritage_land,
    building_preservation_notices,
    world_heritage_site,
    registered_battlefields,
    landmarks,
    scheduled_monuments,
    historic_landscape_areas,
  `]: {
    fillColor: Colour.ORANGE_60,
  },

  employment_area_outer: Colour.YELLOW_60,
  flood_zones_outer: Colour.TEAL_60,
  protected_areas_outer: Colour.MAGENTA_60,
  common_land: Colour.MAGENTA_60,
  settlement_boundaries_outer: Colour.OLIVE_60,
  development_sites_outer: DEVELOPMENT_SITES_COLOUR,

  al_grade_1: generateColor(Colour.RED_60, 0.8),
  al_grade_2: generateColor(Colour.RED_60, 0.9),
  al_grade_3a: generateColor(Colour.RED_60, 1),
  al_grade_3: Colour.YELLOW_60,
  al_grade_3b: generateColor(Colour.GREEN_60, 0.8),
  al_grade_4: generateColor(Colour.GREEN_60, 0.9),
  al_grade_5: generateColor(Colour.GREEN_60, 1),
  al_non_agricultural: Colour.BLUE_60,

  al_scot_grade_1: generateColor(Colour.RED_60, 0.8),
  al_scot_grade_2: generateColor(Colour.RED_60, 0.9),
  al_scot_grade_3_1: generateColor(Colour.RED_60, 1),
  al_scot_grade_3_2: generateColor(Colour.GREEN_60, 0.6),
  al_scot_grade_4: generateColor(Colour.GREEN_60, 0.7),
  al_scot_grade_5: generateColor(Colour.GREEN_40, 0.8),
  al_scot_grade_6: generateColor(Colour.GREEN_40, 0.9),
  al_scot_grade_7: generateColor(Colour.GREEN_40, 1),
  al_scot_grade_888: Colour.BLUE_60,
  al_scot_grade_999: Colour.BLUE_60,
  al_scot_grade_9500: Colour.GREY_60,

  "Application Refused": [233, 0, 10, OPACITY_60],
  "Application Permitted": [65, 197, 65, OPACITY_60],
  "Application Withdrawn": [255, 255, 0, OPACITY_60],
  "lorem_ipsum_parent, lorem_ipsum_lorem, lorem_ipsum_lorem_ipsum": {
    fillColor: Colour.ORANGE_60,
    fillPattern: "hatch-left",
    fillPatternScale: 0.5,
  },
  [`
    safe_guarding_outer,
    rail_safeguarded_land,
    road_safeguarded_land,
    building_safeguarding_(wharf),
    waste_safeguarded_facilities,
    safeguarded_for_gypsy_and_traveller_sites,
    airport_safeguarded_land,
    river_crossing_safeguarding,
    safeguarded_land
  `]: {
    fillColor: Colour.NAVY_40,
  },

  "metropolitan_open_land_outer, metropolitan_open_land": Colour.LIME_60,
  "open_space_outer, metropolitan_green_chains, public_open_space, community_open_space, allotments, registered_historic_parks_and_gardens, educational_buildings_and_playing_fields, green_space":
    Colour.GREEN_60,
  "protected_views, protected_views_outer": Colour.BLUE_60,
  rights_of_way: Colour.RED_80,
  local_planning_authority: {
    fillColor: [255, 255, 255, 0],
    lineColor: Colour.BLUE,
  },
  local_planning_authority_former: {
    fillColor: [255, 255, 255, 0],
    lineColor: Colour.BROWN,
  },
  local_planning_authority_national_park: {
    fillColor: [255, 255, 255, 0],
    lineColor: Colour.GREEN,
  },
  council_boundaries: {
    fillColor: [255, 255, 255, 0],
    lineColor: Colour.MAGENTA,
    labelName: "display_name",
  },
  national_parks: {
    fillColor: [255, 255, 255, 0],
    lineColor: Colour.LIME,
    labelName: "display_name",
  },
  tree_preservation_orders: Colour.MAGENTA_60,
  // declassified_green_belt: {
  //   fillColor: Colour.RED,
  //   fillPattern: "hatch-left",
  //   fillPatternScale: 0.8,
  // },
  land_hub_pipeline: [117, 113, 179, OPACITY_60],
  land_hub_on_market: [27, 158, 119, OPACITY_60],
  land_hub_sold_stc: [217, 95, 2, OPACITY_60],
  repd_solar: {
    pointRadius: 8,
    fillColor: [202, 0, 32, OPACITY_60],
    lineColor: [193, 180, 0],
    lineWidth: 2,
  },
  repd_wind: {
    pointRadius: 8,
    fillColor: [244, 165, 130, OPACITY_60],
    lineColor: [193, 180, 0],
    lineWidth: 2,
  },
  repd_battery: {
    pointRadius: 8,
    fillColor: [238, 59, 241, OPACITY_60],
    lineColor: [193, 180, 0],
    lineWidth: 2,
  },
  repd_other: {
    pointRadius: 8,
    fillColor: [5, 113, 176, OPACITY_60],
    lineColor: [193, 180, 0],
    lineWidth: 2,
  },
  water_neutrality_offsetting_allowed: {
    fillColor: Colour.PURPLE,
    fillPattern: "hatch-left",
    fillPatternScale: 0.8,
  },
  water_neutrality_no_offsetting: {
    fillColor: Colour.RED,
    fillPattern: "hatch-left",
    fillPatternScale: 0.8,
  },
  neighbourhood_plans_plan_made: {
    fillColor: [153, 52, 4, 102],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  neighbourhood_plans_passed_referendum: {
    fillColor: [181, 71, 8, 77],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  neighbourhood_plans_failed_referendum: {
    fillColor: [210, 90, 13, 102],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  neighbourhood_plans_referendum: {
    fillColor: [229, 114, 23, 102],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  neighbourhood_plans_passed_examination: {
    fillColor: [246, 140, 35, 102],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  neighbourhood_plans_failed_examination: {
    fillColor: [254, 167, 63, 102],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  neighbourhood_plans_examination: {
    fillColor: [254, 196, 108, 128],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  neighbourhood_plans_consultation: {
    fillColor: [254, 221, 150, 153],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  neighbourhood_plans_designated: {
    fillColor: [255, 238, 181, 153],
    lineColor: [153, 52, 4, 255],
    lineWidth: 2,
  },
  bng_screening_high: [184, 84, 80, OPACITY_60],
  bng_screening_medium: [223, 192, 99, OPACITY_60],
  bng_screening_low: [171, 191, 117, OPACITY_60],
  national_character_areas: {
    fillColor: [255, 255, 255, 0],
    lineColor: [0, 159, 180],
    labelName: "display_name",
    lineWidth: 2,
  },
  operational_catchments: {
    fillColor: [255, 255, 255, 0],
    lineColor: [0, 33, 132],
    lineWidth: 3,
  },
  waterbody_catchments: {
    fillColor: [255, 255, 255, 0],
    lineColor: [0, 60, 255],
    lineWidth: 1,
  },
  open_rivers: [0, 125, 134, OPACITY_60],
  bng_irreplaceable_phi: Colour.MAGENTA_60,
  open_mosaic_habitats: Colour.MAGENTA_60,
  ancient_tree_inventory: {
    pointRadius: 8,
    fillColor: Colour.MAGENTA_60,
    lineColor: Colour.MAGENTA,
    lineWidth: 2,
  },
  mediterranean_saltmarsh_scrub: Colour.MAGENTA_60,
  spartina_saltmarsh_swards: Colour.MAGENTA_60,
  priority_habitat_inventory: Colour.MAGENTA_60,
  blanket_bog: Colour.MAGENTA_60,
  lowland_fens: Colour.MAGENTA_60,
  limestone_pavements: Colour.MAGENTA_60,
  coastal_sand_dunes: Colour.MAGENTA_60,
  habitat_networks_fragmentation_action_zone: [55, 126, 184, OPACITY_60],
  habitat_networks_habitat_restoration: [77, 175, 74, OPACITY_60],
  habitat_networks_restorable_habitat: [255, 255, 51, OPACITY_60],
  habitat_networks_enhancement_zone_1: [228, 26, 28, OPACITY_60],
  habitat_networks_enhancement_zone_2: [255, 127, 0, OPACITY_60],
  habitat_networks_expansion_zone: [152, 78, 163, OPACITY_60],
  nature_recovery_projects: [0, 139, 146, OPACITY_60],
});

export type CategoryAttributeDataType = "percentage" | "number" | "date" | "string";
export type CategoryAttribute = {
  id: string;
  categoryId: string;
  dataType: CategoryAttributeDataType;
  displayName: string;
  description?: JSX.Element | string;
};

export const CATEGORY_ATTRIBUTES: CategoryAttribute[] = [
  {
    id: "power_substations",
    categoryId: "power",
    displayName: "Substations",
    dataType: "string",
    description: <SubstationDescription />,
  },
  {
    id: "rag_status",
    categoryId: "power",
    displayName: "RAG Status",
    dataType: "string",
    description: <RagStatusDescription />,
  },
  {
    id: "pif",
    categoryId: "local_planning_authority",
    displayName: "Applies",
    dataType: "string",
  },
  {
    id: "pif_proposed",
    categoryId: "local_planning_authority",
    displayName: "Applies",
    dataType: "string",
  },
  {
    id: "hdt21_meas",
    categoryId: "local_planning_authority",
    displayName: "Housing Delivery Test Measurement",
    dataType: "percentage",
  },
  {
    id: "cil_charging_availability",
    categoryId: "local_planning_authority",
    displayName: "Does the authority have a CIL charging structure in place?",
    dataType: "string",
  },
  {
    id: "cil_status",
    categoryId: "local_planning_authority",
    displayName: "CIL status",
    dataType: "string",
  },
  {
    id: "updated_status_date",
    categoryId: "local_planning_authority",
    displayName: "Date of last status update",
    dataType: "string",
  },
  {
    id: "url_link",
    categoryId: "local_planning_authority",
    displayName: "Web link",
    dataType: "string",
  },
  {
    id: "charging_zones_availability",
    categoryId: "local_planning_authority",
    displayName: "Does the CIL schedule have variable charging zones?",
    dataType: "string",
  },
  {
    id: "residential_charges",
    categoryId: "local_planning_authority",
    displayName: "CIL charges for residential development (2024 rates)",
    dataType: "string",
  },
  {
    id: "retail_commercial_charges",
    categoryId: "local_planning_authority",
    displayName: "CIL charges for commercial/retail development (2024 rates)",
    dataType: "string",
  },
  {
    id: "other_charges",
    categoryId: "local_planning_authority",
    displayName: "CIL charges for other developments (2024 rates)",
    dataType: "string",
  },
  {
    id: "mayoral_cil_rates",
    categoryId: "local_planning_authority",
    displayName: "Mayoral CIL rate (£ per sq m, 2024 rates)",
    dataType: "string",
  },
  {
    id: "mayoral_cil_zone",
    categoryId: "local_planning_authority",
    displayName: "Mayoral CIL Band",
    dataType: "string",
  },
  {
    id: "tpo_number",
    categoryId: "tree_preservation_orders",
    displayName: "TPO Number",
    dataType: "string",
  },
  {
    id: "tpo_number_alternative",
    categoryId: "tree_preservation_orders",
    displayName: "TPO Number Alternative",
    dataType: "string",
  },
  {
    id: "tpo_old_number",
    categoryId: "tree_preservation_orders",
    displayName: "TPO Old Number",
    dataType: "string",
  },
  {
    id: "zz_land_hub_developer_guidance",
    categoryId: "land_hub_pipeline",
    displayName: "Guidance on Homes England Hub sites for developers",
    dataType: "string",
  },
  {
    id: "zz_land_hub_register_for_access",
    categoryId: "land_hub_pipeline",
    displayName: "How to register for access to opportunities",
    dataType: "string",
  },
  {
    id: "zz_land_hub_developer_guidance",
    categoryId: "land_hub_on_market",
    displayName: "Guidance on Homes England Hub sites for developers",
    dataType: "string",
  },
  {
    id: "zz_land_hub_register_for_access",
    categoryId: "land_hub_on_market",
    displayName: "How to register for access to opportunities",
    dataType: "string",
  },
  {
    id: "zz_land_hub_developer_guidance",
    categoryId: "land_hub_sold_stc",
    displayName: "Guidance on Homes England Hub sites for developers",
    dataType: "string",
  },
  {
    id: "zz_land_hub_register_for_access",
    categoryId: "land_hub_sold_stc",
    displayName: "How to register for access to opportunities",
    dataType: "string",
  },
  {
    id: "ab_operator_or_applicant",
    categoryId: "repd_solar",
    displayName: "Operator or Applicant",
    dataType: "string",
  },
  {
    id: "a_site_name",
    categoryId: "repd_solar",
    displayName: "Site name",
    dataType: "string",
  },
  {
    id: "a_technology_type",
    categoryId: "repd_solar",
    displayName: "Technology type",
    dataType: "string",
  },
  {
    id: "abc_installed_capacity_(mw)",
    categoryId: "repd_solar",
    displayName: "Installed capacity (mw)",
    dataType: "string",
  },
  {
    id: "abc_site_address",
    categoryId: "repd_solar",
    displayName: "Site address",
    dataType: "string",
  },
  {
    id: "abc_planning_application_reference",
    categoryId: "repd_solar",
    displayName: "Planning application reference",
    dataType: "string",
  },
  {
    id: "abc_development_status",
    categoryId: "repd_solar",
    displayName: "Development status",
    dataType: "string",
  },
  {
    id: "ab_operator_or_applicant",
    categoryId: "repd_wind",
    displayName: "Operator or Applicant",
    dataType: "string",
  },
  {
    id: "a_site_name",
    categoryId: "repd_wind",
    displayName: "Site name",
    dataType: "string",
  },
  {
    id: "a_technology_type",
    categoryId: "repd_wind",
    displayName: "Technology type",
    dataType: "string",
  },
  {
    id: "abc_installed_capacity_(mw)",
    categoryId: "repd_wind",
    displayName: "Installed capacity (mw)",
    dataType: "string",
  },
  {
    id: "abc_site_address",
    categoryId: "repd_wind",
    displayName: "Site address",
    dataType: "string",
  },
  {
    id: "abc_planning_application_reference",
    categoryId: "repd_wind",
    displayName: "Planning application reference",
    dataType: "string",
  },
  {
    id: "abc_development_status",
    categoryId: "repd_wind",
    displayName: "Development status",
    dataType: "string",
  },
  {
    id: "ab_operator_or_applicant",
    categoryId: "repd_battery",
    displayName: "Operator or Applicant",
    dataType: "string",
  },
  {
    id: "a_site_name",
    categoryId: "repd_battery",
    displayName: "Site name",
    dataType: "string",
  },
  {
    id: "a_technology_type",
    categoryId: "repd_battery",
    displayName: "Technology type",
    dataType: "string",
  },
  {
    id: "abc_installed_capacity_(mw)",
    categoryId: "repd_battery",
    displayName: "Installed capacity (mw)",
    dataType: "string",
  },
  {
    id: "abc_site_address",
    categoryId: "repd_battery",
    displayName: "Site address",
    dataType: "string",
  },
  {
    id: "abc_planning_application_reference",
    categoryId: "repd_battery",
    displayName: "Planning application reference",
    dataType: "string",
  },
  {
    id: "abc_development_status",
    categoryId: "repd_battery",
    displayName: "Development status",
    dataType: "string",
  },
  {
    id: "ab_operator_or_applicant",
    categoryId: "repd_other",
    displayName: "Operator or Applicant",
    dataType: "string",
  },
  {
    id: "a_site_name",
    categoryId: "repd_other",
    displayName: "Site name",
    dataType: "string",
  },
  {
    id: "a_technology_type",
    categoryId: "repd_other",
    displayName: "Technology type",
    dataType: "string",
  },
  {
    id: "abc_installed_capacity_(mw)",
    categoryId: "repd_other",
    displayName: "Installed capacity (mw)",
    dataType: "string",
  },
  {
    id: "abc_site_address",
    categoryId: "repd_other",
    displayName: "Site address",
    dataType: "string",
  },
  {
    id: "abc_planning_application_reference",
    categoryId: "repd_other",
    displayName: "Planning application reference",
    dataType: "string",
  },
  {
    id: "abc_development_status",
    categoryId: "repd_other",
    displayName: "Development status",
    dataType: "string",
  },
  {
    id: "a_map_unit_name",
    categoryId: "ssurgo",
    displayName: "Map unit name",
    dataType: "string",
  },
];

const LAND_AVAILABILITY_ASSESSMENT_CATEGORIES = [
  "shlaa_positive",
  "shlaa_neutral",
  "shlaa_negative",
  "brownfield_land",
];

const LISTED_BUILDINGS_CATEGORIES = [
  "statutory_listed_buildings",
  "locally_listed_buildings",
  "heritage_land_listed_buildings_outer",
];

const SITE_ALLOCATION_CATEGORIES = [
  "housing_allocation",
  "residential_allocation",
  "mixed_use_allocation",
  "commercial_allocation",
  "employment_allocation",
  "community_allocation",
  "uncategorised_allocations",
  "site_allocations",
];

const POWER_CATEGORIES = [
  "power_towers",
  "power_overhead_lines",
  "power_cables",
  "grid_power_substations",
  "bulk_power_substations",
  "primary_power_substations",
  "hv_power_substations",
  "repd_solar",
  "repd_wind",
  "repd_battery",
  "repd_other",
];

const OWNERSHIP_CATEGORIES = ["scottish_ownership"];

const REZONING_CATEGORIES = ["rezoning_residential", "rezoning_commercial", "rezoning_mixed_use"];

const BUILDING_PERMIT_CATEGORIES = [
  "us_building_permits_residential",
  "us_building_permits_commercial",
];

const BIODIVERSITY_CATEGORIES = ["ancient_tree_inventory"];

const AMENITY_CATEGORIES = [
  "primary_school",
  "primary_school_independent",
  "secondary_school",
  "secondary_school_independent",
  "further_education",
  "further_education_independent",
  "bus_stop",
  "train_station",
  "metro_station",
  "supermarkets_and_convenience_stores",
  "gp_surgeries",
  "pharmacies",
  "clinics",
  "dentists",
  "hospitals",
] as const;

const US_AMENITY_CATEGORIES = [
  "us_schools_elementary",
  "us_schools_middle",
  "us_schools_high",
  "us_schools_combination",
  "us_schools_other",
  "us_schools_private_elementary",
  "us_schools_private_secondary",
  "us_schools_private_combined",
];

const US_UTILITY_CATEGORIES = [
  "urban_service_area",
  "us_dw_known_public",
  "us_dw_likely_public",
  "us_dw_known_well",
  "us_dw_likely_well",
  "us_dw_conflicting",
  "us_dw_unknown",
  "us_ww_known_sewer",
  "us_ww_likely_sewer",
  "us_ww_known_septic",
  "us_ww_likely_septic",
  "us_ww_conflicting",
  "us_ww_unknown",
];

const MINING_AND_GEOLOGY_CATEGORIES = [
  "coal_mining_reporting_areas",
  "mine_entries",
  "coal_development_high_risk_areas",
  "surface_mining",
  "probable_shallow_coal_workings",
  "past_shallow_coal_workings",
];

const GREY_BELT_CATEGORIES = ["grey_belt_land", "declassified_green_belt"];

export const SELECTABLE_CATEGORIES = [
  ...LAND_AVAILABILITY_ASSESSMENT_CATEGORIES,
  ...OWNERSHIP_CATEGORIES,
  ...POWER_CATEGORIES,
  ...LISTED_BUILDINGS_CATEGORIES,
  ...SITE_ALLOCATION_CATEGORIES,
  ...REZONING_CATEGORIES,
  ...BUILDING_PERMIT_CATEGORIES,
  ...BIODIVERSITY_CATEGORIES,
  ...AMENITY_CATEGORIES,
  ...US_AMENITY_CATEGORIES,
  ...GREY_BELT_CATEGORIES,
];
