export enum ConstraintsCategory {
  ROOT = "root",

  AMENITIES = "amenities",
  AGRICULTURAL_LAND = "agricultural_land",
  ANCIENT_WOODLAND = "ancient_woodland",
  BIODIVERSITY_IMPROVEMENT = "biodiversity_improvement_areas",
  BUILDING_PERMITS = "us_building_permits",
  COUNCIL_BOUNDARIES = "council_boundaries",
  DEVELOPMENT_SITES = "development_sites_outer",
  ENERGY_PROJECTS = "energy_projects",
  ENVIRONMENTAL_PERMITS = "environmental_permits",
  FLUM = "flum",
  HABITATS = "habitats",
  HAZARDS = "hazards",
  LOCAL_PLANNING_AUTHORITY = "local_planning_authority_outer",
  NATIONAL_PARKS = "national_parks",
  NEIGHBOURHOOD_PLAN_AREAS = "neighbourhood_plans",
  OTHER_OPPORTUNITIES = "other_opportunities",
  OWNERSHIP_SCOTLAND = "scottish_ownership",
  POLICY = "policy",
  POWER = "power",
  PROTECTED_AREAS = "protected_areas_outer",
  REZONING_APPLICATIONS = "rezoning_applications",
  SHLAA = "shlaa",
  SOILS_AND_GEOLOGY = "soils_and_geology",
  SPATIAL_RISK_MULTIPLIERS = "bng_spatial_risk_multipliers",
  SUB_STATIONS = "power_substations",
  US_DEMOGRAPHICS = "us_demographics",
  US_OPPORTUNITY_ZONES = "us_opportunity_zones",
  US_STATE_DEMOGRAPHICS = "us_state_demographics",
  US_TRACT_DEMOGRAPHICS = "us_tract_demographics",
  US_COUNTY_DEMOGRAPHICS = "us_county_demographics",
  ZONING = "zoning",
  UK_DEMOGRAPHICS_LSOA = "uk_demographics_lsoa",
  UK_DEMOGRAPHICS_MSOA = "uk_demographics_msoa",
  UK_DEMOGRAPHICS_LAD = "uk_demographics_lad",
  UK_DEMOGRAPHICS_REGION = "uk_demographics_rgn",
  UK_DEMOGRAPHICS_COUNTRY = "uk_demographics_ctry",
  US_AMENITIES = "us_amenities",
  US_UTILITIES = "us_utilities",
  MINING_AND_GEOLOGY = "mining_and_geology",
  GREY_BELT = "grey_belt",
}
