import { FunctionComponent, useCallback, useMemo } from "react";

import { DesignationFeatureProps } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { DesignationHatch } from "react-migration/domains/constraints/components/Hatch";
import { DesignationsList } from "./DesignationsList";
import { SiteOverview } from "react-migration/domains/sites/typings/Site";
import { StaticMapContainer } from "../Map/StaticMapContainer";
import {
  SingleDesignation,
  SingleDesignationSubCategory,
} from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type DesignationSectionProps = {
  category: SingleDesignationSubCategory;
  designations: SingleDesignation[];
  onMapLoaded: (isLoaded: boolean, categoryId: string) => void;
  site: SiteOverview;
};

export const DesignationsReportSection: FunctionComponent<DesignationSectionProps> = ({
  designations,
  category,
  site,
  onMapLoaded,
}) => {
  const { t, checkKey } = useTranslation();
  const onMapLoadedCallback = useCallback(
    (isLoaded: boolean) => onMapLoaded(isLoaded, category.id),
    [onMapLoaded, category]
  );

  const contraintsOptions = useMemo(() => {
    return [
      {
        rootCategory: category.id,
        featureIsVisible: (featureProps: DesignationFeatureProps) =>
          featureProps.sub_category_id === category.id,
      },
    ];
  }, [category]);

  const categoryDisplayName = checkKey(`constraints.categories.site_report.${category.id}`)
    ? t(`constraints.categories.site_report.${category.id}`)
    : category.display_name;

  const defaultMapZoom = 15; // enough zoom to show context around most sites

  return (
    <div className="atlas-h-96 atlas-flex atlas-mb-4 atlas-break-inside-avoid">
      <div className="atlas-w-1/2 atlas-space-y-4">
        <div className="atlas-flex">
          <div className="atlas-mr-2">
            <DesignationHatch id={category.id} />
          </div>
          <h3>{categoryDisplayName}</h3>
        </div>
        <div>{category.description}</div>
        <div>
          <DesignationsList category={category} designations={designations} />
        </div>
      </div>
      <div className="atlas-w-1/2">
        <StaticMapContainer
          mapZoom={defaultMapZoom}
          siteId={site._id}
          onMapLoaded={onMapLoadedCallback}
          constraintsOptions={contraintsOptions}
        />
      </div>
    </div>
  );
};
