import { LtIcons } from "@landtechnologies/icons/font/lt-icons";
import clsx from "clsx";
import { ButtonHTMLAttributes, forwardRef } from "react";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  leadIcon?: `${LtIcons}`;
  variant?: "primary" | "secondary" | "tertiary" | "ghost" | undefined;
};

const primaryStyles = clsx(
  "atlas-text-white atlas-bg-button-primary-base atlas-ring-platform-200",
  "hover:atlas-bg-button-primary-hover",
  "active:atlas-bg-button-primary-pressed",
  "focus:atlas-ring-2",
  "disabled:atlas-bg-button-primary-disabled disabled:atlas-text-platform-400"
);

const secondaryStyles = clsx(
  "atlas-text-platform-950 atlas-bg-platform-50 atlas-ring-platform-600 atlas-ring-2",
  "hover:atlas-bg-platform-100 hover:atlas-ring-platform-700",
  "active:atlas-bg-platform-200 active:atlas-ring-platform-800",
  "focus:atlas-ring-platform-200",
  "disabled:atlas-bg-white disabled:atlas-ring-platform-100 disabled:atlas-text-platform-200"
);
const tertiaryStyles = clsx(
  "atlas-text-platform-950 atlas-bg-neutral-1 atlas-ring-platform-200",
  "hover:atlas-bg-neutral-2",
  "active:atlas-bg-neutral-3",
  "focus:atlas-ring-2",
  "disabled:atlas-bg-white disabled:atlas-text-platform-300"
);
const ghostStyles = clsx(
  "atlas-bg-transparent atlas-ring-platform-200/20",
  "hover:atlas-bg-platformBlue-50",
  "active:atlas-bg-platformBlue-100",
  "focus:atlas-ring-2",
  "disabled:atlas-text-platform-300/40"
);

const variants = {
  primary: primaryStyles,
  secondary: secondaryStyles,
  tertiary: tertiaryStyles,
  ghost: ghostStyles,
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, variant = "primary", className, leadIcon, ...rest }, ref) => {
    return (
      <button
        className={clsx(
          "atlas-inline-flex atlas-items-center atlas-justify-center atlas-relative atlas-isolate atlas-shadow-sm atlas-gap-x-2 atlas-px-3 atlas-py-2 atlas-rounded-lg atlas-text-sm ring-inset",
          variants[variant],
          className
        )}
        ref={ref}
        {...rest}
      >
        {leadIcon && <ButtonIcon icon={leadIcon} />}
        {children}
      </button>
    );
  }
);

function ButtonIcon({ icon }: { icon: `${LtIcons}` }) {
  return <i className={clsx("atlas-text-xl", `icon-${icon}`)} />;
}

Button.displayName = "Button";
